.item {
  height: auto;

  .itemTitle {
    cursor: pointer;
    height: 64px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid var(--liner-gr-green);
    background: var(--main-dark-black);

    .itemTitleContent {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
    }

    .action {
      position: relative;
      width: 16px;
      height: 16px;

      &.active {
        &:before {
          transform: translatey(-50%) rotate(-90deg);
          opacity: 0;
        }
        &:after {
          transform: translatey(-50%) rotate(0);
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        background-color: #bdbdbd;
        position: absolute;
        top: 50%;
        left: 0;
        transition: 0.35s ease-in-out;
        width: 100%;
        height: 2px;
      }

      &:before {
        transform: translatey(-50%);
      }

      &:after {
        transform: translatey(-50%) rotate(90deg);
      }
    }
  }

  .itemText {
    display: flex;
    padding: 0 5px;
    transition: 0.3s ease-in-out;
    overflow: hidden;

    .content {
      padding: 20px 0;

      a {
        color: var(--main-light-green);
        padding: 0 0 0 6px;
      }
    }
  }
}
