.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;

  img {
    animation: bounce 2.5s infinite;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
