@import '/src/mixin.module.scss';

.plan {
  position: relative;
  border-radius: 10px;
  padding: 20px;
  max-height: 132px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  background: linear-gradient(201deg, #08170e, #0a0a0a);
  border: 1px solid #1f3228;

  &.active {
    background: linear-gradient(201deg, #0d1d13, #191818);
    border-color: rgba(0, 255, 117, 0.6);
    max-height: 1000px;
  }

  .list {
    padding: 0;
  }

  .price {
    position: absolute;
    right: 20px;
    top: 36px;

    .text {
      @include screen(small) {
        font-size: 32px;
      }
    }

    @include screen(small) {
      right: 10px;
      top: 10px;
    }
  }

  .currency {
    color: var(--text-gray);

    @include screen(small) {
      line-height: 50px;
    }
  }

  .description {
    color: var(--text-gray);
  }
}
