@import '/src/mixin.module.scss';

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);

  @include screen(medium) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @include screen(small) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 2fr);
  }

  &.gap_0 {
    gap: 0px;
  }

  &.gap_5 {
    gap: 5px;
  }

  &.gap_10 {
    gap: 10px;
  }

  &.gap_20 {
    gap: 20px;
  }

  &.gap_40 {
    gap: 40px;
  }

  &.gap_60 {
    gap: 60px;
  }

  &.gap_80 {
    gap: 80px;
  }

  &.gap_100 {
    gap: 100px;
  }

  &.gap_120 {
    gap: 120px;
  }

  &.gap_140 {
    gap: 140px;
  }
}
