.radioGroup {
  flex-wrap: wrap;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .radioLabel {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background: linear-gradient(201deg, #08170e, #0a0a0a);
    border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 10px;
    min-width: 120px;
    border-radius: 5px;

    &.active {
      pointer-events: none;
      border-color: var(--main-light-green);
    }

    .radioInput {
      display: none;

      &:checked ~ .radioCustom {
        background-color: var(--main-light-green);
        border: 2px solid var(--main-light-green-active);
      }
    }

    .radioCustom {
      display: flex;
      height: 14px;
      width: 14px;
      background-color: var(--text-white);
      border: 2px solid var(--text-disabled);
      border-radius: 50%;
    }
  }
}
