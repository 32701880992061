@import '/src/mixin.module.scss';

body,
html {
  margin: 0;
  min-height: 100vh;
  display: flex;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  background: var(--main-black);
}

:global(#app) {
  width: 100%;
  flex: 1;
  display: flex;
}

:global(#root) {
  flex: 1;
  display: flex;
  flex-direction: column;

  &::after {
    @include radialGradientGreen;
  }
}

:global(*) {
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
}

:global(.Toastify__toast-body) {
  font-size: 13px;
}

:root {
  --text-white: #ffffff;
  --text-gray: #bdbdbd;
  --text-disabled: #858484;

  --liner-gr-green: #255c3d;
  --main-light-green: #00ff75;
  --main-light-green-hover: #4cff9e;
  --main-light-green-active: #0f8043;

  --main-blue: #3498db;

  --main-perpl: #9747ff;
  --main-light-perpl: #8f6cf3;

  --main-red: #e93940;
  --main-light-red: #f84f56;

  --main-orang: #ff6b00;

  --main-black: #0a0a0a;
  --main-dark-black: #000000;
}

// Outlet Styles

:global(#container) {
  flex: 1;
  position: relative;
  z-index: 1;
  padding: 120px 0 140px 0;

  @include screen(small) {
    padding: 60px 0 140px 0;
  }
}
