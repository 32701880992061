.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &.hasClick {
    cursor: pointer;
  }

  &.huge {
    min-width: 50px;
    width: 50px;
    height: 50px;
  }

  &.large {
    min-width: 30px;
    width: 30px;
    height: 30px;
  }

  &.medium {
    min-width: 22px;
    width: 22px;
    height: 22px;
  }

  &.small {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
