@import '/src/mixin.module.scss';

.header {
  height: 84px;
  position: relative;
  z-index: 10;
  max-width: 100%;
  background: var(--main-black);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  .logotype {
    color: var(--text-white);
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
}

.right {
  @include screen(small) {
    display: none;
  }

  .newNotify {
    color: var(--main-light-green);
    display: flex;
    width: 52px;
    height: 26px;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    border-radius: 50px;
    border: 1px solid var(--main-light-green);
    background: rgba(0, 255, 117, 0.06);
    pointer-events: none;
  }

  .signin {
    margin-left: 20px;
  }
}
