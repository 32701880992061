@import '/src/mixin.module.scss';

.footer {
  border-top: 1px solid rgba(0, 255, 117, 0.06);
  background: var(--main-black);

  .separator {
    @include screen(small) {
      display: none;
    }
  }

  .content {
    height: auto;
    padding: 30px 0;

    @include screen(small) {
      flex-direction: column-reverse;
      gap: 30px;
      padding: 30px 0 0 0;
    }

    .logotype {
      color: var(--text-white);
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .sp {
      .image {
        height: 25px;

        img {
          height: auto;
          max-height: 100%;
        }
      }
    }

    .rights {
      color: var(--text-gray);

      @include screen(small) {
        padding: 0 0 30px 0;
      }
    }
  }
}
