.colors {
  .rotationValue {
    min-width: 40px;
    width: 40px;
    text-align: center;
  }

  input[type='color'] {
    margin: 4px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    cursor: pointer;
    height: 30px;
    padding: 0;
    width: 30px;
    border: 2px solid var(--text-white);
  }

  *:focus {
    border-radius: 0;
    outline: none;
  }

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  ::-webkit-color-swatch {
    border: 0;
    border-radius: 0;
  }

  ::-moz-color-swatch,
  ::-moz-focus-inner {
    border: 0;
  }

  ::-moz-focus-inner {
    padding: 0;
  }
}
