@import '/src/mixin.module.scss';

.subscribe {
  position: relative;
  max-width: 485px;
  overflow: hidden;
  padding-top: 20px;

  &::after {
    @include radialGradientGreen;
    transform: translate(-50%, 0) scale(1.2);
  }

  .form {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 463px;
  }
}
