@import '/src/mixin.module.scss';

.mobileMenu {
  display: none;

  @include screen(small) {
    display: flex;
  }

  &.active {
    .button {
      .icon {
        background: transparent;

        &:before {
          transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 6.5px);
        }

        &:after {
          transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -6px);
        }
      }
    }

    .content {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    .icon {
      position: absolute;
      height: 4px;
      width: 30px;
      background-color: var(--text-white);
      transition: 0.3s;

      &:before {
        transition: 0.3s;
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: var(--text-white);
        content: '';
        top: -10px;
      }

      &:after {
        transition: 0.3s;
        position: absolute;
        width: 30px;
        height: 4px;
        background-color: var(--text-white);
        content: '';
        top: 10px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .content {
    transform: translateX(100%);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: 0.3s;
    z-index: -1;
    padding: 0 25px;

    .contentWrapper {
      height: calc(100% - 153px);
    }

    &:before {
      content: '';
      display: flex;
      width: calc(100% + 50px);
      height: 83px;
      margin: 0 -25px 40px -25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    .link {
      font-size: 20px;
    }

    .newNotify {
      color: var(--main-light-green);
      display: flex;
      width: 52px;
      height: 26px;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
      border-radius: 50px;
      border: 1px solid var(--main-light-green);
      background: rgba(0, 255, 117, 0.06);
      pointer-events: none;
    }

    .signin,
    .signout {
      width: 100%;
      max-width: 420px;
      margin-top: auto;
    }
  }
}
