.rangeWrapper {
  display: flex;
  width: 100%;

  .range {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: var(--text-disabled);
    outline: none;
    transition: opacity 0.2s;
    border-radius: 2.5px;

    &:focus {
      border-radius: 2.5px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: var(--main-light-green);
      cursor: pointer;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: var(--main-light-green);
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
