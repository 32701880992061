@import '/src/mixin.module.scss';

.input {
  margin: 0;
  color: var(--text-white);
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  box-sizing: border-box;
  padding: 5px;
  height: 38px;
  border: 1px solid var(--liner-gr-green);
  background: var(--main-dark-black);
  position: relative;
  width: 64px;
  min-width: 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  text-align: center;

  @include screen(small) {
    font-size: 14px;
    height: 48px;
  }

  &:hover,
  &:focus {
    caret-color: var(--main-light-green);
    color: var(--text-white);
    outline: 0;
  }

  &::placeholder {
    color: var(--text-gray);
  }
}

.col {
  max-width: calc(100% / 3);
}
