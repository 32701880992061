@import '/src/mixin.module.scss';

.notify {
  border-radius: 10px;
  background: rgba(0, 255, 117, 0.07);
  padding: 10px 12px;

  .icon {
    @include screen(small) {
      display: none;
    }
  }

  .text {
    color: var(--main-light-green);

    @include screen(small) {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
  }
}
