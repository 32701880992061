.space {
  height: auto;
}

.container {
  background-color: var(--liner-gr-green);
}

.relative {
  position: relative;
}
