.container {
  padding: 0 25px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &.large {
    max-width: 1200px;
  }

  &.medium {
    max-width: 1024px;
  }

  &.small {
    max-width: 980px;
  }
}
