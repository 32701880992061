.select {
  position: relative;
  z-index: 3;

  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 0;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 9px 13px;
    font-size: 16px;
    line-height: 26px;
    border: 1px solid;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    background: var(--main-light-green);
    color: var(--main-black);
    border-color: transparent;
    cursor: pointer;
    width: 160px;
  }

  &.small {
    .value {
      padding: 5px 12px;
      font-size: 14px;
    }
  }
}
