.nodata {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .nodata_text {
    letter-spacing: 1px;
    color: var(--text-gray);
  }
}
