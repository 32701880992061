.QRCodeImageWrapper {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  padding: 10px;
  background: #fff;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
