@import '/src/mixin.module.scss';

.leftMenu {
  padding: 40px;
  border-radius: 10px;
  background: var(--main-dark-black);
  width: 100%;
  max-width: 303px;
  height: 580px;

  @include screen(small) {
    display: none;
  }

  .content {
    overflow: hidden;

    .item {
      transition: 0.3s ease-in-out;

      .text {
        color: var(--text-gray);
      }

      &.signOut {
        margin-top: auto;
      }

      &:hover,
      &.active {
        .text {
          color: var(--main-light-green);
        }
      }

      &.active {
        pointer-events: none;
      }

      &:hover {
        .text {
          color: var(--text-white);
        }
      }

      .icon {
        width: 24px;
      }
    }
  }
}
