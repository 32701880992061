@import '/src/mixin.module.scss';

.phoneWrapper {
  width: 310px;
  height: 640px;
  border: 3px solid #cecece;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  background-color: #000;

  @include screen(small) {
    margin: 0 -50px;
  }

  .phoneScreen {
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    padding: 20px;

    .phoneHeader {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 10px;
      background-color: #cecece;
      border-radius: 15px;
      z-index: 2;
    }

    .phoneLayout {
      position: absolute;
      top: 40px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
