@mixin radialGradientGreen {
  content: '';
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 100%;
  max-width: 1084px;
  padding-bottom: 100%;
  transform: translate(-50%, -50%);
  background-image: url('./assets/images/common/RadialBackgroundGreen.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: blink 5s infinite;
}

@mixin radialGradientRed {
  content: '';
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 100%;
  max-width: 1084px;
  padding-bottom: 100%;
  transform: translate(-50%, -50%);
  background-image: url('./assets/images/common/RadialBackgroundRed.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: blink 5s infinite;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.5;
  }
}

@mixin screen($size) {
  @if $size == verySmall {
    @media only screen and (max-width: 480px) {
      @content;
    }
  } @else if $size == small {
    @media only screen and (max-width: 768px) {
      @content;
    }
  } @else if $size == msmall {
    @media only screen and (min-width: 769px) {
      @content;
    }
  } @else if $size == medium {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  } @else if $size == large {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  } @else if $size == veryLarge {
    @media only screen and (max-width: 1920px) {
      @content;
    }
  } @else {
    @error "Unsupported screen size.";
  }
}
