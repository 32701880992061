.wrapper {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  .image {
    width: 140px;
    min-height: 140px;
    height: 140px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }

  .text {
    color: var(--text-gray);
  }

  .button {
    width: 100%;
    position: relative;
    padding: 9px 44px;
    min-height: 46px;

    .buttonImage {
      position: absolute;
      left: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .footer {
    padding-top: 20px;
    width: 100%;
    text-align: center;
    color: var(--text-gray);

    .footerLink {
      color: var(--main-light-green);
    }
  }
}
