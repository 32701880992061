@import '/src/mixin.module.scss';

.title {
  margin: 0;
  color: var(--text-white);
  font-style: normal;

  &.Raleway {
    font-family: 'Raleway', sans-serif;
  }

  &.Inter {
    font-family: 'Inter', sans-serif;
  }

  &.size_1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;

    @include screen(small) {
      font-size: 36px;
      line-height: 52px;
    }
  }
}

.paragraph {
  margin: 0;
  color: var(--text-white);
  font-style: normal;

  &.Raleway {
    font-family: 'Raleway', sans-serif;
  }

  &.Inter {
    font-family: 'Inter', sans-serif;
  }

  &.size_1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;

    @include screen(small) {
      font-size: 22px;
    }
  }

  &.size_2 {
    font-size: 21px;
    font-weight: 700;
    line-height: 31px;

    @include screen(small) {
      font-size: 18px;
    }
  }
}

.text {
  margin: 0;
  color: var(--text-white);
  font-style: normal;

  &.Raleway {
    font-family: 'Raleway', sans-serif;
  }

  &.Inter {
    font-family: 'Inter', sans-serif;
  }

  &.size_1 {
    font-size: 60px;
    font-weight: 700;
    line-height: 58px;
  }

  &.size_2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;

    @include screen(small) {
      font-size: 14px;
    }
  }

  &.size_3 {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  }
}
