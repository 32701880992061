.tableContainer {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  position: relative;
  z-index: 1;

  .tableHeader {
    position: sticky;
    top: 0;
    z-index: 10;

    .tableHeaderCell {
      padding: 10px;
      border-bottom: 2px solid var(--main-light-green);

      .tableHeaderCellContent {
        display: flex;
        align-items: center;
        gap: 5px;

        &.sortable {
          cursor: pointer;

          * {
            cursor: pointer;
          }
        }
      }

      &.left {
        .tableHeaderCellContent {
          justify-content: flex-start;
        }
      }

      &.center {
        .tableHeaderCellContent {
          justify-content: center;
        }
      }

      &.right {
        .tableHeaderCellContent {
          justify-content: flex-end;
        }
      }

      * {
        color: var(--main-light-green);
      }
    }
  }

  .tableRow {
    &.loading {
      td {
        padding: 10px;
        text-align: center;
      }
    }

    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .tableCell {
      padding: 10px;

      &.left {
        text-align: left;
      }

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }
    }
  }
}
