.loader {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;

    img {
      animation: bounce 2.5s infinite;
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
