.ScreenCapture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  box-shadow: rgb(3 255 117) 0px 0px 0px 2px;
  border-radius: 10px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
