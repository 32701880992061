.backButton {
  gap: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  opacity: 1;
  transition: 0.3s ease-in-out;
  width: fit-content;

  &:hover {
    opacity: 0.5;
  }
}
