@import '/src/mixin.module.scss';

.plan {
  height: auto;
  position: relative;
  flex-basis: calc(33% - 11px);
  border-radius: 10px;
  padding: 40px 20px;
  background: linear-gradient(201deg, #08170e, #0a0a0a);

  @include screen(medium) {
    flex-basis: calc(50% - 10px);
    min-height: 600px;
  }

  @include screen(small) {
    flex-basis: 100%;
    margin: 0 auto;
    max-width: 400px;
  }

  &.active {
    background: linear-gradient(201deg, #0d1d13, #191818);

    &:after {
      background-image: linear-gradient(
        201deg,
        rgba(0, 255, 117, 0.6),
        rgba(0, 255, 117, 0.6)
      );
    }
  }

  &:after {
    transition: 0.3s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background-image: linear-gradient(201deg, #27352d, #161616);
  }

  .price {
    height: auto;
  }

  .currency {
    color: var(--text-gray);
  }

  .description {
    color: var(--text-gray);
    padding: 0 0 20px 0;
  }
}
