.image {
  .text {
    color: var(--text-gray);
  }

  .uploadButton {
    width: 120px;

    &:hover {
      background: var(--main-light-red);
      border-color: transparent;
      cursor: pointer;
    }
  }

  .icons {
    max-width: 470px;

    .icon {
      position: relative;
      cursor: pointer;
      padding: 10px;
      background: #ffffff;
      border: 0;
      border-radius: 4px;

      &.remove {
        padding: 0;
        background: transparent;
      }

      &.active {
        pointer-events: none;

        &:after {
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          border: 3px solid var(--main-perpl);
          border-radius: 4px;
        }
      }
    }
  }
}
