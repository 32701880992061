.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  margin: 0;
  padding: 0 0 40px 0;
  list-style-type: none;

  .item {
    &.hasIcon {
      position: relative;
      padding-left: 40px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        width: 26px;
        height: 26px;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .value {
      color: var(--text-gray);
    }
  }
}
