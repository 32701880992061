@import '/src/mixin.module.scss';

.redirect {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 810px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;

  * {
    position: relative;
    z-index: 1;
  }

  &.success {
    &::after {
      @include radialGradientGreen;
      transform: translate(-50%, 0) scale(1.2);
    }
  }

  &.failure {
    &::after {
      @include radialGradientRed;
      transform: translate(-50%, 0) scale(1.2);
    }
  }

  .link {
    color: var(--main-light-green);

    &:hover {
      cursor: pointer;
      color: var(--main-light-green-hover);
    }
  }
}
