@import '/src/mixin.module.scss';

.charts {
  padding: 0;
}

.filters {
  position: relative;

  .input {
    margin: 0;
    color: var(--text-white);
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    box-sizing: border-box;
    padding: 5px 5px 5px 15px;
    height: 56px;
    border: 1px solid var(--liner-gr-green);
    background: var(--main-dark-black);
    position: relative;
    width: 100%;
    min-width: 0;
    border-radius: 10px;
    transition: 0.3s ease-in-out;

    @include screen(small) {
      font-size: 14px;
      height: 48px;
    }

    &:hover,
    &:focus {
      caret-color: var(--main-light-green);
      color: var(--text-white);
      outline: 0;
    }

    &::placeholder {
      color: var(--text-gray);
    }
  }

  .select_wrapper {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.clickable {
  color: cornflowerblue;

  cursor: pointer;

  &:hover {
    color: dodgerblue;
  }
}

.scenario_payload {
  &.open-url {
    color: var(--main-light-green);
  }

  &.link-in-bio {
    color: var(--main-perpl);
  }
}

.preview {
  transform: scale(0.86);
}
