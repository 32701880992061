@import '/src/mixin.module.scss';

.createForm {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #1f3228;
  padding: 40px 60px 40px 40px;
  background: linear-gradient(
    201deg,
    #09361c -68.72%,
    rgba(0, 0, 0, 0) 108.41%
  );

  @include screen(small) {
    padding: 20px 30px 20px 30px;
  }
}
