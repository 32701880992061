@import '/src/mixin.module.scss';

.wrapper {
  @include screen(small) {
    flex-direction: column-reverse !important;
    align-items: center !important;
  }
}

.body {
  max-width: calc(100% - 296px);

  @include screen(small) {
    max-width: 100%;
  }
}

.preResult {
  width: 100%;
  max-width: 256px;

  .preResultContent {
    width: 256px;

    &.fixed {
      position: fixed;
      top: 20px;

      @include screen(small) {
        position: relative;
        left: 0 !important;
        top: 0 !important;
      }
    }
  }

  .QRCodeImageWrapper {
    overflow: hidden;
    width: 256px;
    height: 256px;
    min-height: 256px;
    border-radius: 10px;
    padding: 10px;
    background: linear-gradient(225deg, rgb(255 255 255 / 35%), transparent);

    img {
      width: 100%;
      height: 100%;
    }
  }
}
