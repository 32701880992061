@import '/src/mixin.module.scss';

.navBar {
  min-height: 66px;
  padding: 0 0 20px 0;

  @include screen(small) {
    width: 100%;
    gap: 0 !important;
  }

  .suffix {
    margin-left: auto;
    padding-left: 10px;
  }

  .navItem {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;

    @include screen(small) {
      flex: 1;
      justify-content: center;
    }

    .navItemText {
      color: var(--text-gray);
    }

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    &.active {
      pointer-events: none;
    }

    &.hovered {
      cursor: pointer;
    }

    &.hovered,
    &.active {
      .navItemText {
        color: var(--text-white);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -7px;
        left: 0px;
        right: -6px;
        height: 2px;
        border-radius: 10px;
        background: var(--main-light-green);

        @include screen(small) {
          left: 5px;
          right: 5px;
        }
      }
    }
  }
}
