@import '/src/mixin.module.scss';

.statistic {
  .statistic_row {
    &:not(:last-child) {
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px dashed #262626;
    }

    .statistic_key {
      color: var(--text-gray);
    }
  }
}

.charts {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 40px 0 0 0;
  width: 100%;

  .row {
    flex-wrap: wrap;
    height: auto;

    &.mobileReverse {
      @include screen(small) {
        flex-direction: column-reverse;
      }
    }

    .col {
      flex: 1;

      &.col-3 {
        max-width: 33.33%;

        @include screen(small) {
          max-width: 100%;
          width: 100%;
          flex: auto;
        }
      }

      @include screen(small) {
        max-width: 100%;
        width: 100%;
        flex: auto;
      }

      .chart {
        position: relative;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #1f3228;
        background: var(--main-black, #0a0a0a);
        min-height: 300px;
      }
    }
  }
}
