@import '/src/mixin.module.scss';

.faq {
  display: flex;
  gap: 100px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: calc(100vw - 50px);
  overflow: hidden;


  @include screen(medium) {
    flex-direction: column;
    gap: 40px;
  }

  .title,
  .body {
    flex: 1;
    max-width: 100%;
  }
}
