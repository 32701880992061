@import '/src/mixin.module.scss';

.collectionCard {
  padding: 20px;
  border-radius: 10px;
  position: relative;

  @include screen(small) {
    flex-wrap: wrap;
    justify-content: space-between !important;
  }

  &.not-active, // need to add
  &.open-url {
    border: 1px solid #1f3228;
    background: linear-gradient(
      201deg,
      #09361c -68.72%,
      rgba(0, 0, 0, 0) 108.41%
    );

    .trigger_description {
      color: #00ff75;
    }
  }

  &.file-share {
    border: 1px solid #914f1e;
    background: linear-gradient(
      201deg,
      rgba(255, 107, 0, 0.62) -68.72%,
      rgba(0, 0, 0, 0) 108.41%
    );

    .trigger_description {
      color: #ff6b00;
    }
  }

  &.link-in-bio {
    border: 1px solid #5e3b8b;
    background: linear-gradient(
      201deg,
      rgba(151, 71, 255, 0.42) -68.72%,
      rgba(0, 0, 0, 0) 108.41%
    );

    .trigger_description {
      color: #8f6cf3;
    }
  }

  &.video-player {
    border: 1px solid #3a1d91;
    background: linear-gradient(
      201deg,
      rgba(66, 0, 255, 0.64) -68.72%,
      rgba(0, 0, 0, 0) 108.41%
    );

    .trigger_description {
      color: #9747ff;
    }
  }

  .image {
    min-width: 140px;
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .trigger_head {
    @include screen(small) {
      flex: auto;

      .trigger_name {
        padding: 0;
      }
    }
  }

  .trigger_details {
    flex: 1;
    padding: 0 20px 0 0;

    @include screen(small) {
      order: 2;
      flex: auto;
      padding: 0;
    }

    * {
      line-height: 35px;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include screen(small) {
        max-width: 220px;
      }
    }
  }

  .trigger_count {
    min-width: 120px;

    .trigger_interaction_count {
      line-height: 70px;
    }

    .count_title {
      line-height: 35px;
    }

    @include screen(small) {
      padding-top: 17.5px;
    }
  }

  .trigger_date {
    color: var(--text-gray);
  }

  .trigger_name {
    line-height: 45px;
    padding-bottom: 25px;
  }

  .trigger_actions_btn {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin: 7.5px 5px;
  }
}
