.plans {
  .myPlan {
    position: relative;
    border-radius: 10px;
    padding: 20px;
    background: var(--main-black);
    border: 1px solid #1f3228;

    .available {
      position: absolute;
      right: 20px;
      top: 0;
      border-radius: 0px 0px 5px 5px;
      padding: 5px 10px;
      background: var(--main-blue);
      font-size: 14px;
      color: var(--text-white);
    }

    .name {
      color: var(--main-light-green);
    }

    .description {
      color: var(--text-gray);
    }

    .limits {
      color: var(--text-white, #fff);
    }
  }
}
