.upload {
  position: relative;
  border: 2px dashed #858484;
  padding: 20px;
  cursor: pointer;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #252525;
  }

  .remove,
  .file,
  .uploadInput {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .file {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 1;

    .fileImage {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }

  .uploadInput {
    cursor: pointer;
    z-index: 2;
  }
}
