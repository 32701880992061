@import '/src/mixin.module.scss';

.space {
  display: flex;

  &.hiddenOnMobile {
    display: flex;

    @include screen(small) {
      display: none;
    }
  }

  &.hiddenOnDesktop {
    display: flex;

    @include screen(msmall) {
      display: none;
    }
  }

  &.hasClick {
    cursor: pointer;
  }

  &.wrapped {
    flex-wrap: wrap;
  }

  &.fullWidth {
    width: 100%;
  }

  &.fullHeight {
    height: 100%;
  }

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }

  &.align_start {
    align-items: start;
  }

  &.align_end {
    align-items: end;
  }

  &.align_middle {
    align-items: center;
  }

  &.align_normal {
    align-items: normal;
  }

  &.justify_start {
    justify-content: flex-start;
  }

  &.justify_end {
    justify-content: flex-end;
  }

  &.justify_middle {
    justify-content: center;
  }

  &.justify_between {
    justify-content: space-between;
  }

  &.justify_around {
    justify-content: space-around;
  }

  &.justify_normal {
    justify-content: normal;
  }

  &.gap_0 {
    gap: 0px;
  }

  &.gap_5 {
    gap: 5px;
  }

  &.gap_10 {
    gap: 10px;
  }

  &.gap_20 {
    gap: 20px;
  }

  &.gap_40 {
    gap: 40px;
  }

  &.gap_60 {
    gap: 60px;
  }

  &.gap_80 {
    gap: 80px;
  }

  &.gap_100 {
    gap: 100px;
  }

  &.gap_120 {
    gap: 120px;
  }

  &.gap_140 {
    gap: 140px;
  }
}
