.switchGroup {
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;

    input {
      display: none;
    }

    input:checked + .slider {
      background-color: var(--main-light-green);
    }

    input:focus + .slider {
      box-shadow: 0 0 1px var(--main-light-green);
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--text-disabled);
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: var(--text-white);
      border-radius: 50%;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
  }
}
