@import '/src/mixin.module.scss';

.wrapper {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: 0.3s ease-in-out;

  &.active {
    opacity: 1;
  }

  .modal {
    position: relative;
    z-index: 2;
    padding: 50px;
    border-radius: 20px;
    border: 1px solid var(--main-light-green);
    background: var(--main-dark-black);
    width: calc(100% - 10px);

    @include screen(small) {
      padding: 25px;
    }

    &.large {
      max-width: 900px;
    }

    &.medium {
      max-width: 640px;
    }

    &.small {
      max-width: 500px;
    }

    &.active {
      opacity: 1;
    }

    .closeIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      transition: 0.3s ease-in-out;

      &:hover {
        opacity: 0.5;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}
