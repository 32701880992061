.icons {
  .icon {
    width: 40px;
    height: 40px;
    padding: 5px;
    min-width: 0;
    border-radius: 5px;

    &:hover {
      transform: scale(1.2);
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
