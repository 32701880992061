.separator {
  width: 100%;
  border-bottom: 1px solid #1f2421;

  &.padding_0 {
    padding: 0 0;
  }

  &.padding_10 {
    padding: 10px 0 0 0;
    margin: 0 0 10px 0;
  }

  &.padding_20 {
    padding: 20px 0 0 0;
    margin: 0 0 20px 0;
  }

  &.padding_40 {
    padding: 40px 0 0 0;
    margin: 0 0 40px 0;
  }
}
