@import '/src/mixin.module.scss';

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .fieldTitle {
    padding-bottom: 10px;

    @include screen(small) {
      padding-bottom: 5px;
    }
  }

  &.disabled {
    .fieldTitle,
    .input {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  &.hasSuffixIcon {
    .input {
      padding-right: 56px;

      @include screen(small) {
        padding-right: 48px;
      }
    }

    .suffixIcon {
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include screen(small) {
        right: 6px;
        bottom: 6px;
      }
    }
  }

  &.hasSuffix {
    .input {
      padding-right: 160px;
    }

    .suffix {
      position: absolute;
      border: 0;
      right: 5px;
      bottom: 5px;
      width: 150px;
      height: 46px;

      @include screen(small) {
        height: 38px;

        button {
          padding: 5px 33px;
        }
      }
    }
  }

  &.error {
    .input {
      border-color: #e93940;
      color: #e93940;

      &:hover,
      &:focus {
        caret-color: #e93940;
      }
    }
  }

  &.success {
    .input {
      border-color: var(--liner-gr-green);
      color: var(--main-light-green);
    }
  }
}

.input {
  margin: 0;
  color: var(--text-white);
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  box-sizing: border-box;
  padding: 5px 5px 5px 15px;
  height: 56px;
  border: 1px solid var(--liner-gr-green);
  background: var(--main-dark-black);
  position: relative;
  width: 100%;
  min-width: 0;
  border-radius: 10px;
  transition: 0.3s ease-in-out;

  @include screen(small) {
    font-size: 14px;
    height: 48px;
  }

  &:hover,
  &:focus {
    caret-color: var(--main-light-green);
    color: var(--text-white);
    outline: 0;
  }

  &::placeholder {
    color: var(--text-gray);
  }
}
