@import '/src/mixin.module.scss';

.headline {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 1024px;
  width: 100%;

  @include screen(small) {
    align-items: center;

    .title,
    .description {
      text-align: center;
    }
  }

  &.left {
    align-items: flex-start;

    .title,
    .description {
      text-align: left;
    }
  }

  .title {
    text-align: center;

    @include screen(small) {
      text-align: center;
    }
  }

  .description {
    color: var(--text-gray);
    text-align: center;
  }
}
