@import '/src/mixin.module.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  border: 1px solid;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  white-space: nowrap;

  &.hiddenOnMobile {
    display: flex;

    @include screen(small) {
      display: none;
    }
  }

  &.hiddenOnDesktop {
    display: flex;

    @include screen(msmall) {
      display: none;
    }
  }

  &:focus {
    border-radius: 5px !important;
  }

  &.fullWidth {
    width: 100%;
  }

  // Sizes

  &.large {
    padding: 9px 33px;
    font-size: 16px;

    &.iconButton {
      padding: 9px;
    }
  }

  &.medium {
    padding: 9px 33px;
    font-size: 16px;

    &.iconButton {
      padding: 9px;
    }
  }

  &.small {
    padding: 5px 12px;
    font-size: 14px;

    &.iconButton {
      padding: 5px;
    }
  }

  // Colors

  &.default {
    color: var(--text-white);
    background: rgba(0, 255, 117, 0);
    border-color: rgba(255, 255, 255, 0.12);

    &.active {
      background: var(--main-light-green);
      color: var(--main-black);
      border-color: transparent;
    }

    &.disabled {
      color: var(--text-disabled);
      background: var(--text-gray);
      pointer-events: none;
    }

    &:hover {
      background: var(--main-light-green-hover);
      border-color: transparent;
      cursor: pointer;
    }
  }

  &.primary {
    background: var(--main-light-green);
    color: var(--main-black);
    border-color: transparent;

    &.active {
      background: var(--main-light-green-active);
      border-color: transparent;
    }

    &.disabled {
      color: var(--text-disabled);
      background: var(--text-gray);
      pointer-events: none;
    }

    &:hover {
      background: var(--main-light-green-hover);
      border-color: transparent;
      cursor: pointer;
    }
  }

  &.danger {
    background: var(--main-red);
    color: var(--text-white);
    border-color: transparent;

    &.active {
      background: var(--main-light-red);
      border-color: transparent;
    }

    &.disabled {
      color: var(--text-disabled);
      background: var(--text-gray);
      pointer-events: none;
    }

    &:hover {
      background: var(--main-light-red);
      border-color: transparent;
      cursor: pointer;
    }
  }
}
