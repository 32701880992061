@import '/src/mixin.module.scss';

.statistic {
  position: relative;
  padding-left: 40px;
  flex-basis: calc(33% - 24px);

  @include screen(medium) {
    max-width: 320px;
  }

  @include screen(small) {
    max-width: 280px;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .title {
    padding-bottom: 10px;
  }

  .value {
    color: var(--text-gray);
  }
}
