.menu {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 10px;
  overflow: hidden;
  height: 0;
  z-index: 3;

  &.isChildren {
    right: calc(100% - 15px);
    top: -20px;

    .wrapper {
      background: #0c0c0c;
    }
  }

  &.active {
    height: auto;
    overflow: visible;

    &.isChildren {
      padding: 20px;
    }
  }

  .wrapper {
    padding: 10px;
    border-radius: 10px;
    background: var(--main-dark-black, #000);

    .button {
      white-space: nowrap;
      padding: 5px 10px 5px 5px;
      border-radius: 10px;
      width: 100%;

      &.relative {
        position: relative;
      }

      &:hover {
        cursor: pointer;
        background: rgb(255 255 255 / 7%);
      }

      &.red {
        color: var(--main-red);
      }

      &.green {
        color: var(--main-light-green);
      }
    }
  }
}
