@import '/src/mixin.module.scss';

.relative {
  position: relative;
}

.preview {
  transform: scale(0.86);
}

[data-rbd-draggable-context-id] {
  width: 100%;
}

.wrappedField {
  @include screen(small) {
    flex-direction: column !important;
  }
}

.uploadField {
  width: 36px;
  height: 36px;
  overflow: hidden;
  position: relative;
  background: var(--main-light-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &:hover {
    background: var(--main-light-green-hover);
  }

  * {
    cursor: pointer;
  }

  .icon {
    pointer-events: none;
  }

  .input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}

.link_in_bio_form {
  @include screen(small) {
    flex-direction: column !important;
    align-items: center !important;
  }
}

.droppable {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card {
  position: relative;
  border-radius: 10px;
  border: 1px solid #1f3228;
  padding: 20px;
  margin-bottom: 20px;
  background: linear-gradient(
    201deg,
    #09361c -68.72%,
    rgba(0, 0, 0, 0) 108.41%
  );

  .closeIcon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.qrCode {
  max-width: 190px;
  max-height: 190px;
}

.qrCodeName {
  @include screen(small) {
    display: none;
  }
}

.result {
  .date {
    color: var(--text-gray);
  }

  .link {
    max-width: 380px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include screen(small) {
      max-width: 170px;
    }
  }
}

.submit_btn {
  @include screen(small) {
    width: 100%;
  }
}
