.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  background: #101e12;

  .text {
    color: var(--main-light-green);
  }
}
